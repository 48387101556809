
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import processImageLink from '../../mixins/processImageLink.js'
import ReusableLoading from '../common/ReusableLoading.vue';
import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';

export default {
  name: 'HeroBanner',

  components: { VueSlickCarousel },

  mixins: [eventTrackingMixins, processImageLink],

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 5000,
        // lazyLoad: 'progressive',
        responsive: [],
        background: '#ff0000',
        swipeToSlide: true,
      },
      responsiveSettings: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
      areResonsiveSettingsApplied: false,
      isSwiping: false, // fixing the swipe issue
      isMouseDown: false, // fixing the swipe issue
    };
  },

  // workaround solution taken from https://github.com/gs-shop/vue-slick-carousel/issues/94
  // due to hydration issue when declaring responsive setting directly
  methods: {
    initHandler() {
      if (this.areResonsiveSettingsApplied === false) {
        this.applyResponsiveSettings();
      }
    },
    applyResponsiveSettings() {
      this.settings.responsive = this.responsiveSettings;
      this.areResonsiveSettingsApplied = true;
    },
    handleEventTracking(extraData){
      // #164
      const eventTrackingParams = this.initEventTrackingParams()
      const link = extraData.link
      const pattern = /.*\/([-a-zA-Z\d/]+)(\?.*)bannercategory=([^&]+).*?$/s
      const match = link.match(pattern)

      eventTrackingParams.a = 'homepage_banner_click'
      eventTrackingParams.c = 'homepage_banner'
      eventTrackingParams.l = link
      if(match) {
        eventTrackingParams.cd.product_category = match[3] ? match[3] : undefined
      }

      this.sendEventTracking(eventTrackingParams)
    },
    // fixing the swipe issue on desktop
    beforeChangeHandler() {
      if ( !this.isMobile() ) this.isSwiping = true;
    },
    afterChangeHandler() {
      if ( !this.isMobile() ) this.isSwiping = false;
    },
    handleMouseDown() {
      if ( !this.isMobile() ) this.isMouseDown = true;
    },
    handleMouseMove() {
      if (!this.isMobile() && this.isMouseDown) {
        this.isSwiping = true;
      }
    },
    handleMouseUp() {
      if ( !this.isMobile() ) {
        this.isMouseDown = false;
        this.isSwiping = false;
      }
    },
    handleClick(event) {
      if (!this.isMobile() && this.isSwiping) {
        event.stopImmediatePropagation();
        this.isSwiping = false;
      }
    },
    isMobile() {
      // for detecting iPad pro workaround
      if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) return true;

      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
};
